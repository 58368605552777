import React, { useState } from "react"
import { LegacyCard, LegacyStack, TextField, Text, ChoiceList } from "@shopify/polaris";
import update from "immutability-helper"
import styled from "styled-components"
import { DISCOUNT_TYPES } from "../../../../constants/constants"
const CouponWrapper = styled.div`
  padding-top: 1.5rem;
  .coupon-title {
    display: block;
    padding: 5px 0;
  }
  .coupon-items {
    padding-top: 1.5rem;
    display: flex;
    flex-direction: row;
    gap: 15px;
    .coupon-item:first-of-type {
      flex: 0 0 auto;
    }
    .coupon-item:nth-child(2) {
      width: 125px;
    }
    .coupon-item:last-of-type {
      flex: 1 1 auto;
    }
  }
`

function DiscountCard(props) {
  const { state, setState, currency, currencySymbol = "$" } = props
  const [isFirstButtonActive, setIsFirstButtonActive] = useState(
    !state?.discount?.sym || state?.discount?.sym === "$" ? true : false
  )
  const [selectedType, setSelectedType] = useState(!state?.discount?.sym || state?.discount?.sym === currencySymbol ? 'fixedAmount' : 'percentage');
  const handleFirstButtonClick = () => {
    const discount = { sym: currencySymbol, value: "" }
    if (isFirstButtonActive) return
    setState(update(state, { discount: { $set: discount } }))
    setIsFirstButtonActive(true)
  }

  const handleSecondButtonClick = () => {
    const discount = { sym: "%", value: "" }
    setState(update(state, { discount: { $set: discount } }))
    setIsFirstButtonActive(false)
  }

  const handleDiscountTypeChange = (type) => {
    const discount = {sym: type === 'percentage' ? '%' : currencySymbol, value: ''}
    if ((type === 'percentage' && "%" === state?.discount?.sym) || (type === 'fixedAmount' && currencySymbol === state?.discount?.sym) ) return;
    setState(update(state, { discount: { $set: discount } }))
    setSelectedType(type);
  }
  return (
    <CouponWrapper>
      <LegacyCard sectioned>
              <Text variant="headingSm" as="h3">Discounts</Text>
              <LegacyStack vertical={true}>
                <ChoiceList
                    title="Type"
                    choices={DISCOUNT_TYPES}
                    selected={selectedType}
                    onChange={(value) => handleDiscountTypeChange(value[0])}
                  />
                  <TextField
                    onChange={(val) => {
                      const newVal = "percentage" === selectedType && val > 100 ? 100 : val
                      setState(update(state,  { discount: { $set: {sym:"fixedAmount" === selectedType ? currencySymbol : '%', value:newVal} }}))
                    }}
                    value={state.discount.sym ? String(state.discount.value): state.discount}
                    min={0}
                    max={"percentage" === selectedType ? 100 : null}
                    type={'text'}
                    suffix={"fixedAmount" === selectedType ? currency:'%'}
                  />
              </LegacyStack>
            </LegacyCard>
    </CouponWrapper>
  );
}

export default DiscountCard
